<template>
  <ul>
    <li>
      <label>
        <input type="checkbox" v-model="items" value="read" /> Visualizar
      </label>
    </li>
    <li>
      <label>
        <input type="checkbox" v-model="items" value="create" /> Crear
      </label>
    </li>
    <li>
      <label>
        <input type="checkbox" v-model="items" value="update" /> Editar
      </label>
    </li>
    <li>
      <label>
        <input type="checkbox" v-model="items" value="delete" /> Eliminar
      </label>
    </li>
  </ul>
</template>
<script>
export default {
  props:{
    value:{
      type: Array,
      default: () => [],
    }
  },
  computed:{
    items:{
      get(){
        return this.value ?? [];
      },
      set(e){
        this.$emit('input', e)
      }
    }
  }
}
</script>