<template>
  <div>
    <title-bar
      :items="[
        { title: 'Usuarios', to: `/users` },
        { title: `Permisos del usuario` },
      ]"
    >
    </title-bar>
    <div class="box">
      <h1 class="is-size-3"><b-icon icon="user"></b-icon> {{user && user.persona.nombre_completo}}</h1>
      <ul>
        <li class="my-2">
          <label class="has-text-weight-bold">
            <input type="checkbox" @change="handleCheck" v-model="inputCheck" value="informes" /> Informes
          </label>
          <div style="margin-left: 10px">
            <InputsRoles v-model="rol.informes" @input="handleCheckParants" />
          </div>
        </li>
        <li class="my-2">
          <label class="has-text-weight-bold">
            <input type="checkbox" @change="handleCheck" v-model="inputCheck" value="paginas" /> Páginas
          </label>
          <div style="margin-left: 10px">
            <InputsRoles v-model="rol.paginas" @input="handleCheckParants" />
          </div>
        </li>
        <li class="my-2">
          <label class="has-text-weight-bold">
            <input type="checkbox" @change="handleCheck" v-model="inputCheck" value="graficos" /> Gráficos
          </label>
          <div style="margin-left: 10px">
            <InputsRoles v-model="rol.graficos" @input="handleCheckParants" />
          </div>
        </li>
      </ul>
      <div class="pt-3">
        <b-button 
          icon-left="save" 
          type="is-primary" 
          :disabled="loading"
          :loading="loading"
          @click="handleSaveRoles">Guardar permisos</b-button>
      </div>
    </div>
  </div>
</template>

<script>
import InputsRoles from "../components/roles/InputsRoles";
export default {
  components: {
    InputsRoles,
  },
  data(){
    return {
      user: null,
      loading: false,
      rol: {},
      inputCheck: [],
    }
  },
  computed:{
    name_store(){
      return `users`
    },
  },
  async created(){
    const {id} = this.$route.params
    if(id){
      this.user = await this.$store.dispatch(`${this.name_store}/get`, id)
      if(this.user) {
        this.$set(this, 'rol', this.user.persona.attrs.roles ?? {
          informes: [],
          paginas: [],
          graficos: [],
        })
        this.handleCheckParants()
        return;
      }
    }
    this.$store.dispatch("app/set404", true)
  },
  methods: {
    handleCheck(e){
      const key = e.target.value
      const status = e.target.checked
      const value = status ? ['read','create','update','delete'] : []
      this.$set(this.rol, key, value)
    },
    async handleSaveRoles(){
      this.loading = true;
      const data = await this.$store.dispatch(`${this.name_store}/save`,{id: this.user.id, roles: this.rol})
      if(data){
        this.$store.dispatch(
          "app/setToastSuccess",
          `Se acaba de guardar los permisos de: "${this.user.persona.nombre_completo}"`
        );
      }
      this.loading = false;
    },
    handleCheckParants(){
      Object.entries(this.rol).map(([key, value]) => {
        if(value.length){
          if(this.inputCheck.indexOf(key) < 0){
            this.inputCheck = [...this.inputCheck, key]
          }
        } else {
          this.inputCheck = this.inputCheck.filter(e => e !== key)
        }
      })
    }
  }
};
</script>